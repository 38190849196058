#rebateTransfer {
  .form-container {
    border-radius: 8px;
    background-color: $white;
    padding: 20px;

    .el-form {
      border-radius: 6px;
      background-color: $light-gray;
      max-width: 904px;
      margin: 0 auto;
      padding: 20px;

      .main {
        display: grid;
        grid-template-columns: 35px 400px;
        column-gap: 35px;
        margin: 0 auto;
        max-width: 500px;

        .right {
          width: 400px;

          p {
            font-size: 15px;
            color: $blue;
            text-transform: uppercase;
            line-height: 1.799;
            margin: 10px 0;
          }

          li:first-child .el-form-item {
            position: relative;

            .link {
              position: absolute;
              @include rtl-sass-prop(right, left, -78px);
              @include rtl-sass-prop-sd(transform, rotate(180deg));
              top: 23px;
              height: 283px;
            }
          }

          /deep/ .el-form-item {
            max-width: 321px;
          }

          .el-button {
            width: 100%;
            max-width: 321px;
          }
        }
      }
    }
  }
}

/deep/ .el-steps {
  .el-step:nth-child(1) {
    flex-basis: 310px !important;
  }

  .el-step:nth-child(2) {
    flex-basis: calc(100% - 350px) !important;
  }
}

@media (max-width: 767px) {
  #rebateTransfer .form-container .el-form {
    background-color: $white;
    padding: 0;

    .main {
      column-gap: 10px;
      grid-template-columns: 30px calc(100% - 30px - 10px);

      .right {
        width: 100%;

        li:first-child .el-form-item .link {
          content: url(~@/assets/images/rebateTransfer/small_link.png);
          @include rtl-sass-prop(right, left, -55px);
          height: 275px;
        }

        /deep/ .el-form-item {
          width: 80%;

          .el-input__inner {
            border-style: solid;
            border-width: 1px;
            border-color: rgba(39, 5, 109, 0.1);
            border-radius: 6px;
            background-color: $light-gray;
          }
        }
      }
    }
  }
}
